const DataBundleManagement = () => import("@/views/data/data-bundle-management.view.vue");

export const dataRoutes = [
    {
        path: '/data/manage',
        name: 'My Data Bundles',
        component: DataBundleManagement,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'cube'
        }
    }
];