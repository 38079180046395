const BillingPaymentHistory = () => import("@/views/billing/billing-invoices.view.vue");

export const billingRoutes = [
    {
        path: '/bill/invoices',
        name: 'Transaction History',
        component: BillingPaymentHistory,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'history'
        }
    }
];