<template>
  <div class="center-block text-center">
    <div class="three-cogs">
      <font-awesome-icon
        :icon="['fa', 'cog']"
        class="light cog-one"
        :size="`${7}x`"
      />
      <font-awesome-icon
        id="2"
        :icon="['fa', 'cog']"
        class="light cog-two"
        :size="`${4}x`"
      />
      <font-awesome-icon
        id="3"
        :icon="['fa', 'cog']"
        class="light cog-three"
        :size="`${3}x`"
      />
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "card-loader",
  props: ["text", "customClass"]
};
</script>
<style lang="scss" scoped>
.spinner-container {
  position: relative;
}
.logo {
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px;
  margin-left: -40px;
  margin-top: 50px;
  animation: pulse 2s linear infinite;
}
.spinner {
  position: relative;
  height: 180px;
  width: 180px;
  border: 6px solid transparent;
  border-top-color: $dark;
  border-left-color: $dark;
  border-radius: 50%;
  -webkit-animation: spin 1.25s linear infinite;
  animation: spin 1.25s linear infinite;
  display: block;
  margin: auto;
  margin-top: 16px;
}
.spinner::before {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  content: "";
  border: 6px solid transparent;
  border-top-color: $success;
  border-left-color: $success;
  border-radius: 50%;
  -webkit-animation: spinBack 1.25s linear infinite;
  animation: spinBack 1.25s linear infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

.three-cogs {
  position: relative;
  width: 200px;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 32px;
  .cog-one {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    left: 0;
    background: $success;
    border-radius: 100%;
    padding: 10px;
    font-weight: 100;
  }
  .cog-two {
    animation-name: spin-reverse;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    background: $success;
    border-radius: 100%;
    padding: 10px;
    font-weight: 100;
    right: -36px;
    bottom: 37px;
  }
  .cog-three {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    position: absolute;
    bottom: -16px;
    right: -4px;
    background: $success;
    border-radius: 100%;
    padding: 10px;
    font-weight: 100;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
