const AccountManage = () => import('@/views/account/account-manage.view.vue');
const AccountReactivateVoiceProduct = () => import('@/views/account/account-reactivate-voice-product.view.vue');
const AccountSuspended = () => import('@/views/account/account-suspended.view.vue');
export const accountRoutes = [
    {
        path: '/account/manage/:tab?/:secondary?',
        name: 'account manage',
        component: AccountManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'users',
        }
    },
    {
        path: '/account/voice/reactivate/:id',
        name: 'account voice product re-activate',
        component: AccountReactivateVoiceProduct,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'users',
        }
    },
    {
        path: '/account/suspended',
        name: 'account suspended',
        component: AccountSuspended,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'users',
        }
    },
];