const ProductsManageView = () => import("@/views/product/product-management.view.vue");

export const productRoutes = [
    {
        path: '/product/manage',
        name: 'Active Products',
        component: ProductsManageView,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'box-open'
        }
    }
];