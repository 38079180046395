const CampaignView = () => import("@/views/campaign/campaign.view.vue");

export const campaignRoutes = [
    {
        path: '/campaign/:step?',
        name: 'campaign voice',
        component: CampaignView,
        meta: {
            navigation: true,
            sideNavBar: false,
            isPublic: false,
            icon: 'circle'
        }
    }
];