import Vue from "vue";
import App from "./App.vue";
import router from "@/routes/routes";
import store from "./store";
import loader from '@/components/custom/loader.component.vue';
import pulseLoader from '@/components/custom/pulse-loader.component.vue';
import 'element-ui/lib/theme-chalk/display.css';
import '@/styles/element-ui/element-ui.css';
import '@/styles/style.scss';
import { Logger } from "@MyTelnet/mytelnet-client-logging-library";
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import {
    Alert,
    Aside,
    Badge,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    Col,
    Container,
    DatePicker,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Footer,
    Form,
    FormItem,
    Header,
    Input,
    InputNumber,
    Loading,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    MessageBox,
    Notification,
    Option,
    OptionGroup,
    Pagination,
    Radio,
    RadioButton,
    Row,
    Select,
    Slider,
    Step,
    Divider,
    Progress,
    TabPane,
    Tabs,
    Submenu,
    TimeSelect,
    TimePicker,
    Tooltip,
    Upload,
    Timeline,
    TimelineItem,
    Table,
    Tag,
    Switch,
    TableColumn,
    Steps,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    PageHeader,
    Popover
} from 'element-ui';
import { library } from "../local_modules/@fortawesome/fontawesome-svg-core";
locale.use(lang)
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faCheckCircle,
    faCircle,
    faCog,
    faCogs,
    faExchangeAlt,
    faInfoCircle,
    faPhone,
    faSignOutAlt,
    faSpinner,
    faTachometerAlt,
    faTimesCircle,
    faUser,
    faUserCircle,
    faUsers,
    faUserTie,
    faPhoneVolume,
    faListOl,
    faChartBar,
    faQuestion,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faHeadset,
    faArrowAltCircleLeft,
    faMoneyBill,
    faFileAlt,
    faFileInvoice,
    faHistory,
    faWifi,
    faBox,
    faCube,
    faBoxOpen,
    faIdBadge,
    faTimes,
    faCalendar,
    faStopwatch
} from "../local_modules/@fortawesome/free-solid-svg-icons";
import {
    faPen as faPen_light,
    faInfoCircle as faInfoCircle_light,
    faWallet as faWallet_light,
    faUsers as faUsers_light,
    faPhoneVolume as faPhoneVolume_light,
    faLaughWink as faLaughWink,
    faUserHeadset as faUserHeadset_light,
    faPhoneSquareAlt as faPhoneSquareAlt_light,
    faCreditCard as faCreditCard_light,
    faTimes as faTimes_light,
    faListOl as faListOl_light,
    faPhoneSlash as faPhoneSlash_light,
    faCalendar as faCalendar_light
} from "../local_modules/@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "../local_modules/@fortawesome/vue-fontawesome"
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(Button.name, Button);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(Dialog.name, Dialog);
Vue.component(InputNumber.name, InputNumber);
Vue.component(TabPane.name, TabPane);
Vue.component(Tabs.name, Tabs);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Submenu.name, Submenu);
Vue.component(Form.name, Form);
Vue.component(Input.name, Input);
Vue.component(FormItem.name, FormItem);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(Radio.name, Radio);
Vue.component(RadioButton.name, RadioButton);
Vue.component(TimeSelect.name, TimeSelect);
Vue.component(TimePicker.name, TimePicker);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Upload.name, Upload);
Vue.component(Alert.name, Alert);
Vue.component(Col.name, Col);
Vue.component(Row.name, Row);
Vue.component(MessageBox.name, MessageBox);
Vue.component(Notification.name, Notification);
Vue.component(Main.name, Main);
Vue.component(Header.name, Header);
Vue.component(Container.name, Container);
Vue.component(Footer.name, Footer);
Vue.component(Aside.name, Aside);
Vue.component(Badge.name, Badge);
Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(BreadcrumbItem.name, BreadcrumbItem);
Vue.component(Slider.name, Slider);
Vue.component(Card.name, Card);
Vue.component(Timeline.name, Timeline);
Vue.component(TimelineItem.name, TimelineItem);
Vue.component(Table.name, Table);
Vue.component(Tag.name, Tag);
Vue.component(Switch.name, Switch);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Pagination.name, Pagination);
Vue.component(Steps.name, Steps);
Vue.component(Step.name, Step);
Vue.component(Divider.name, Divider);
Vue.component(Progress.name, Progress);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Popover.name, Popover);
Vue.component(PageHeader.name, PageHeader);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('loader', loader);
Vue.component('pulse-loader', pulseLoader);
Vue.use(Loading.directive);

locale.use(lang)
library.add(faCircle,
    faCube,
    faIdBadge,
    faBoxOpen,
    faTimes,
    faIdBadge,
    faBox,
    faHistory,
    faFileInvoice,
    faFileAlt,
    faMoneyBill,
    faArrowAltCircleLeft,
    faTachometerAlt,
    faUsers,
    faUserTie,
    faUser,
    faCog,
    faCogs,
    faEyeSlash,
    faEye,
    faChartBar,
    faSignOutAlt,
    faPhoneVolume,
    faExchangeAlt,
    faSpinner,
    faCheckCircle,
    faPhone,
    faCircle,
    faTimesCircle,
    faArrowAltCircleDown,
    faInfoCircle,
    faArrowAltCircleUp,
    faUserCircle,
    faListOl,
    faExclamationCircle,
    faQuestion,
    faArrowAltCircleUp,
    faWifi,
    faHeadset,
    faUserCircle,
    faInfoCircle,
    faSignOutAlt,
    faCogs,
    faCog,
    faUser,
    faUserTie,
    faUsers,
    faTachometerAlt,
    faArrowAltCircleDown,
    faFileInvoice,
    faExchangeAlt,
    faSpinner,
    faPhone,
    faCheckCircle,
    faTimesCircle,
    faBox,
    faArrowAltCircleLeft,
    faEye,
    faEyeSlash,
    faExclamationCircle,
    faExchangeAlt,
    faPhoneVolume,
    faListOl,
    faPen_light,
    faInfoCircle_light,
    faUsers_light,
    faWallet_light,
    faUserHeadset_light,
    faPhoneSquareAlt_light,
    faLaughWink,
    faPhoneVolume_light,
    faUsers_light,
    faCreditCard_light,
    faTimes_light,
    faListOl_light,
    faPhoneSlash_light,
    faCalendar,
    faHistory);
Vue.config.productionTip = false;
Vue.prototype.$appName = 'My App'
Vue.prototype.$message = Message;
Vue.prototype.$messaged = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$logger = Logger
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_TAG_ID?.toString() },
    appName: 'Mytelnet Customer Portal',
    pageTrackerScreenviewEnabled: false
}, router);
console.log('%cMyTelnet\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t Voice | Data | Cloud', 'color: #fff;background:#202020;font-size:16px;padding:16px;width:1600px;font-family: Poppins, sans-serif !important;');
console.log(`%cCopyright Mytelnet (PTY) Ltd 2013 - ${(new Date().getFullYear())} - All rights reserved`, 'color: #00c694;font-family: Poppins, sans-serif !important;');
new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App)
}).$mount("#app");
