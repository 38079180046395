<template>
    <div id="app">
        <dev-banner v-if="environment != 'Production'" :environment="environment" :style="toggleMargin()"></dev-banner>
        <router-view :class="showMenu ? 'container' : ''" :style="toggleMargin()" />
        <side-bar v-if="showMenu"></side-bar>
    </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Cookie } from "./common/cookie";
import { Theme } from "./common/theme";
const sideBarComponent = () => import("@/components/custom/side-bar.component.vue");
const devBannerComponent = () => import("@/components/custom/custom-dev-banner.component.vue");
export default Vue.extend({
    components: {
        "side-bar": sideBarComponent,
        "dev-banner": devBannerComponent,
    },
    data() {
        return {
            isCollapse: false,
        };
    },
    computed: {
        showMenu() {
            if ((this as any).$route.meta.isPublic === true || (this as any).$route.meta.sideNavBar === false) {
                return false;
            } else {
                return true;
            }
        },
        environment() {
            return process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : "Production";
        },
        maintenance() {
            return process.env.VUE_APP_MAINTENANCE_MODE === "true" ? true : false;
        },
    },
    mounted() {
        let value = Cookie.get("THEME");
        if (value) {
            Theme.setTheme("dark-mode");
        }
        if ((this as any).$route.meta.isPublic) {
            Theme.setTheme("auth");
        }
        if (this.maintenance) {
            this.$router.push("/maintenance");
        }
        this.$root.$on("side-nav-toggle", value => {
            this.isCollapse = value;
        });
    },
    methods: {
        toggleMargin() {
            if (this.showMenu) {
                return this.isCollapse ? "margin-left:64px" : "margin-left:240px";
            }
            return "";
        },
        getBannerClass() {
            if (this.environment != "Production") {
                return this.showMenu ? `margin-left-sidebar-space padding-top padding-left padding-right` : `padding-top padding-left padding-right`;
            } else {
                return "";
            }
        },
    },
});
</script>
<style lang="scss" scoped>
.container {
    padding: 16px;
}
.margin-left-sidebar-space {
    margin-left: 240px;
}
</style>
