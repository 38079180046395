<template>
  <div>
    <div class="pulse-loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({} as any);
</script>
<style lang="scss" scoped>
.pulse-loader {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
}
.pulse-loader span {
  vertical-align: middle;
  border-radius: 100%;

  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: pulse-loader 0.8s linear infinite alternate;
  animation: pulse-loader 0.8s linear infinite alternate;
}
.pulse-loader span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 0, 0, 0.6);
}
.pulse-loader span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 0, 0, 0.8);
}
.pulse-loader span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  background: rgba(0, 0, 0, 1);
}
.pulse-loader span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 0, 0, 0.8);
}
.pulse-loader span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 0, 0, 0.4);
}

@keyframes pulse-loader {
  from {
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes pulse-loader {
  from {
    -webkit-transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
  }
}
</style>