import { Cookie } from './cookie';
export class Campaign {
    public static async check(to: any, from: any, next: any) {
        let token = Cookie.get("MTLAUTH");
        if (token) {
            let result = Cookie.parseToken(token);
            if (!result.customerId || !result.campaignTrack || result.campaignTrack < 4) {
                next('/campaign')
            }
        }
        next();
    }
}