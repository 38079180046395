const UserList = () => import("@/views/voice/fusion/user/user-list.view.vue");
const UserAdd = () => import("@/views/voice/fusion/user/user-add.view.vue");
const UserManage = () => import("@/views/voice/fusion/user/user-manage.view.vue");
const CallReports = () => import("@/views/voice/cerebro/reporting/call-reports.view.vue");
const NumbersList = () => import('@/views/voice/fusion/number/number-list.view.vue');
const NumberAdd = () => import('@/views/voice/fusion/number/number-add.view.vue');
const NumberManage = () => import('@/views/voice/fusion/number/number-manage.view.vue');
const RingGroupList = () => import('@/views/voice/fusion/ring-group/ring-group-list.view.vue');
const RingGroupAdd = () => import('@/views/voice/fusion/ring-group/ring-group-add.view.vue');
const RingGroupManage = () => import('@/views/voice/fusion/ring-group/ring-group-manage.view.vue');
const IVRList = () => import('@/views/voice/fusion/ivr/ivr-list.view.vue');
const IVRAdd = () => import('@/views/voice/fusion/ivr/ivr-add.view.vue');
const IVRManage = () => import('@/views/voice/fusion/ivr/ivr-manage.view.vue');
const PortingRequestsList = () => import('@/views/voice/cerebro/porting-request/porting-request-list.view.vue');
const PortingRequestsAdd = () => import('@/views/voice/cerebro/porting-request/porting-request-add.view.vue');
const VoiceTopUp = () => import("@/views/voice/cerebro/top-up/voice-top-up.view.vue");
const TimeCondtionAddView = () => import("@/views/voice/fusion/time-conditions/time-conditions-add.view.vue");
const TimeCondtionUpdateView = () => import("@/views/voice/fusion/time-conditions/time-condtions-manage.view.vue");
const TimeCondtionListView = () => import("@/views/voice/fusion/time-conditions/time-conditions-list.view.vue");
export const voiceRoutes = [
    {
        path: '/user/list',
        name: 'user extensions',
        component: UserList,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'users'
        }
    },
    {
        path: '/user/add',
        name: 'user add',
        component: UserAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'users'
        }
    },
    {
        path: '/user/manage/:id',
        name: 'user manage',
        component: UserManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'users'
        }
    },

    {
        path: '/group/list',
        name: 'ring groups',
        component: RingGroupList,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'phone-volume',
        }
    },
    {
        path: '/group/add',
        name: 'group add',
        component: RingGroupAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'phone-volume',
        }
    },
    {
        path: '/group/manage/:id',
        name: 'group manage',
        component: RingGroupManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'phone-volume',
        }
    },
    {
        path: '/ivr/list',
        name: 'Digital Receptionists',
        component: IVRList,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'headset',
        }
    },
    {
        path: '/ivr/add',
        name: 'ivr add',
        component: IVRAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'headset',
        }
    },
    {
        path: '/ivr/manage/:id',
        name: 'ivr manage',
        component: IVRManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'headset',
        }
    },
    {
        path: '/numbers/list',
        name: 'phone numbers',
        component: NumbersList,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'list-ol',
        }
    },
    {
        path: '/numbers/manage/:id',
        name: 'number add',
        component: NumberManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'phone-volume',
        }
    },
    {
        path: '/numbers/add',
        name: 'number manage',
        component: NumberAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'phone-volume',
        }
    },
    {
        path: '/reports/call-records',
        name: 'call records',
        component: CallReports,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'chart-bar',
        }
    },
    {
        path: '/bill/top-up',
        name: 'airtime top up',
        component: VoiceTopUp,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'money-bill',
        }
    },
    {
        path: '/porting/add',
        name: 'add porting request',
        component: PortingRequestsAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'exchange-alt',
        }
    },
    {
        path: '/porting/request',
        name: 'porting requests',
        component: PortingRequestsList,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: 'exchange-alt',
        }
    },
    {
        path: '/time-condition/list',
        name: "Time Conditions",
        component: TimeCondtionListView,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'calendar',
        }
    },
    {
        path: '/time-condition/add',
        name: "Time Condition Add",
        component: TimeCondtionAddView,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'exchange-alt',
        }
    },
    {
        path: '/time-condition/manage/:tcid',
        name: "Time Condition Update",
        component: TimeCondtionUpdateView,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: 'exchange-alt',
        }
    },

];